<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <SubscriptionModal content-id="3"/>
    <div class="container-fluid">
      <div class="row">
        <HomeSection v-if="!mobile" id="sec1" :imgLeft="false" :infoSec="locale[lang].COACH_JEUNES.INFOSEC1" :haveButton="false" />
        <MobileSec id="mobile1" v-else :infoSec="locale[lang].COACH_JEUNES.INFOSEC1" :titleTop="false" :haveButton="false" />
      </div>
      <div class="row">
        <HomeSection v-if="!mobile" id="sec2" class="padding" :imgLeft="true" :infoSec="locale[lang].COACH_JEUNES.INFOSEC2" :haveButton="false" />
        <MobileSec id="mobile2" v-else :infoSec="locale[lang].COACH_JEUNES.INFOSEC2_MOBILE" :titleTop="false" :haveButton="false" />
      </div>
      <div class="row" id="items">
        <div class="col-12">
          <div class="title" data-aos="fade-right">
            <h1>
              {{locale[lang].COACH_JEUNES.LIST_1.TITLE}}
            </h1>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(text, key) in locale[lang].COACH_JEUNES.LIST_1.ITEMSL" :key="key" data-aos="fade-right">
                <CoachItem :img="false" :text="text" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(text, key) in locale[lang].COACH_JEUNES.LIST_1.ITEMSR" :key="key" data-aos="fade-left">
                <CoachItem :img="false" :text="text" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" data-aos="fade-right">
        <div class="col-12 titrs">
          <h1 v-if="!mobile">
            {{locale[lang].COACH_JEUNES.LIST_1.END}}
          </h1>
        </div>
      </div>
      <div class="row justify-content-center" id="secRotM">
        <h1 data-aos="fade-right" v-if="mobile">
          {{locale[lang].COACH_JEUNES.ROTATE.TITLE}}
        </h1>
        <RotateSec :mobile="mobile" id="secRot" :iconInfo="iconInfo" />
      </div>
      <div class="row">
        <div class="col-12" id="summary">
          <h1 data-aos="fade-right">
            {{locale[lang].COACH_JEUNES.ROTATE.END}}
          </h1>
        </div>
        <HomeSection v-if="!mobile" id="sec3" class="padding" :imgLeft="true" :infoSec="locale[lang].COACH_JEUNES.INFOSEC3" :haveButton="true" :modal="false" :link="locale[lang].BLOG_LINK" />
        <MobileSec id="mobile3" v-else :infoSec="locale[lang].COACH_JEUNES.INFOSEC3_MOBILE" :titleTop="false" :haveButton="true" :modal="false" :link="locale[lang].BLOG_LINK" />
      </div>
      <div class="row row-card">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 col-card" v-for="(card, key) in locale[lang].COACH_JEUNES.CARDS" :key="key"
          :id="'card-' + key">
          <div class="card-coach">
            <img class="img-fluid" :src="require('@/assets/Images/Coaches/' + card.img)" alt="card-img" />
            <h5>{{ card.text }}</h5>
          </div>
          <div class="button-card row">
            <div class="col-6" v-for="(button, key) in card.buttons" :key="key">
            <button class="btn btn-sc" @click="goTo(button.link, button.external)">{{ button.text }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <AppSection id="appsec" :appSec="appSec" :titleTop="mobile" />
      </div>
      <div class="row" id="checks">
        <div class="col-12">
          <div class="title">
            <h1>
              {{locale[lang].COACH_JEUNES.LINKS.TITLE}}
            </h1>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-right">
              <div class="row" v-for="(check, key) in locale[lang].COACH_JEUNES.LINKS.CHECKSL" :key="key" :id="'checkL-' + key">
                <button class="btn check" @click.prevent="goTo(check.next, false)">
                  <i class="fas fa-chevron-right"></i>
                  {{ check.text }}
                </button>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-left">
              <div class="row" v-for="(check, key) in locale[lang].COACH_JEUNES.LINKS.CHECKSR" :key="key" :id="'checkR-' + key">
                <button class="btn check" @click.prevent="goTo(check.next, false)">
                  <i class="fas fa-chevron-right"></i>
                  {{ check.text }}
                </button>
              </div>
            </div>
            <div class="col-12" data-aos="fade-right">
              <div class="row justify-content-center">
                <button class="btn check" id="lastCheck" @click.prevent="goTo(locale[lang].COACH_JEUNES.LINKS.CHECKM.next, false)">
                  <i class="fas fa-chevron-right"></i>
                  {{ locale[lang].COACH_JEUNES.LINKS.CHECKM.text }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="last">
        <div class="col-12">
          <div class="title">
            <h1 data-aos="fade-right">
              {{locale[lang].COACH_JEUNES.LIST_2.TITLE}}
            </h1>
          </div>
          <div class="row" v-for="(text, key) in locale[lang].COACH_JEUNES.LIST_2.ITEMSA" :key="key" data-aos="fade-right">
            <CoachItem :img="true" :text="text" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import HomeSection from "@/components/Home/HomeSection.vue";
import AppSection from "@/components/Home/AppSection.vue";
import CoachItem from "@/components/CoachItem.vue";
import RotateSec from "@/components/Home/RotateSec.vue";
import MobileSec from "@/components/MobileSec.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  components: {
    HomeSection,
    AppSection,
    CoachItem,
    RotateSec,
    MobileSec,
    NavBar,
    Footer
  },
  created() {
    var w = window.innerWidth;
    this.appSec = this.locale[this.lang].COACH_JEUNES.APPSEC;
    this.iconInfo[0].text = this.locale[this.lang].COACH_JEUNES.ROTATE.ICONINFO[0];
    this.iconInfo[1].text = this.locale[this.lang].COACH_JEUNES.ROTATE.ICONINFO[1];
    this.iconInfo[2].text = this.locale[this.lang].COACH_JEUNES.ROTATE.ICONINFO[2];
    if (w <= 1024) {
      this.mobile = true;
      this.iconInfo[0].text = this.locale[this.lang].COACH_JEUNES.ROTATE.ICONINFO_MOBILE[0];
      this.iconInfo[1].text = this.locale[this.lang].COACH_JEUNES.ROTATE.ICONINFO_MOBILE[1];
      this.iconInfo[2].text = this.locale[this.lang].COACH_JEUNES.ROTATE.ICONINFO_MOBILE[2];
      this.appSec.button = this.locale[this.lang].COACH_JEUNES.APPSEC.button_mobile;
    }
  },
  data() {
    return {
      mobile: false,
      iconInfo: [
        {
          img: "coach.png",
          text: ``,
        },
        {
          img: "letter.png",
          text: ``,
        },
        {
          img: "heart-msg.png",
          text: ``,
        },
      ],
    };
  },
  methods: {
    goTo(next, external) {
      if(!external) {
        if (next || next == !"") {
          this.$router.push({ name: next });
        }
      } else {
        window.open(next, "_blank")
      }
    },
  },
};
</script>

<style scoped>
.padding {
  padding: 0 120px;
}

#nav-container {
  background-color: #0f1010!important;
}

#sec1 {
  background-image: url("../assets/Images/Coaches/Enmascarar grupo 129.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: bottom;
}

#sec1 >>> .text {
  padding: 80px 30px 0px 250px !important;
}

#sec1 >>> h5 {
  text-align: left;
  font: normal normal 500 30px/48px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

#sec2 >>> p {
  text-align: left;
  font: normal normal 500 28px/45px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.row-card {
  padding: 18% 10% 22% 10%;
  background-image: url("../assets/Images/Coaches/Enmascarar grupo 133.svg");
  background-repeat: no-repeat;
  background-size: auto;
}

.col-card {
  position: relative;
}

.card-coach {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #8f8f8f26;
  border: 1px solid #d4d4d4;
  border-radius: 15px;
  opacity: 1;
  display: inline-flex;
  padding: 70px 0;
}

.card-coach h5 {
  text-align: left;
  font: normal normal 600 21px/34px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
  margin-right: 25%;
}

#card-1 h5 {
  margin-right: 15% !important;
}

.card-coach img {
  margin: 0 28px;
}

#card-1 img {
  margin-left: 60px;
}

.button-card {
  position: absolute;
  bottom: -15%;
  left: 0;
  right: 0;
  margin: auto;
}

#card-0 .button-card .col-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

#card-1 .button-card button {
  margin-right: 10px;
  padding: 28px 50px;
}

.button-card button {
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: #0f1010;
  border: 2px solid #ffffff !important;
  border-radius: 15px;
  text-align: center;
  font: normal normal bold 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 28px 120px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
}

.button-card button:hover {
  background-color: rgba(255, 0, 0, 0.507);
  margin-left: 20px;
}

#sec3 >>> h2,
.title h1,
.titrs h1,
#summary h1 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.titrs {
  margin-bottom: 100px;
}

.titrs h1 {
  text-align: center !important;
}

#summary {
  padding-right: 38%;
  padding-left: 15%;
}

#secRot {
  background-image: url("../assets/Images/Coaches/Enmascarar grupo 132@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  padding: 10% 12%;
}

#secRot >>> .rotate {
  height: 120px;
  width: 120px;
  margin-left: 85px;
}

#secRot >>> #rot-0 .img-fluid {
  top: 6%;
  left: 41.5%;
}

#secRot >>> #rot-1 {
  padding-top: 40px;
}

#secRot >>> #rot-1 .img-fluid {
  top: 17%;
  left: 42.3%;
}

#secRot >>> #rot-2 {
  padding-top: 110px;
}

#secRot >>> #rot-2 .img-fluid {
  top: 36%;
  left: 41.7%;
}

#sec3 {
  margin-bottom: 100px;
}

#sec3 >>> p {
  text-align: left;
  font: normal normal 500 22px/32px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

#sec3 >>> .button-sec {
  text-align: left;
}

#sec3 >>> .btn {
  text-align: center;
  font: normal normal bold 26px/32px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
    padding: 20px 100px;

}

#appsec >>> #first {
  padding-left: 0px !important;
}

#appsec >>> #first div.p-1 {
  padding: 0 !important;
}

#appsec >>> #second {
  padding-top: 200px;
  padding-right: 12%;
}

#appsec >>> .button-sec {
  margin-top: 120px;
  margin-left: 0;
}

#appsec >>> .button-sec::after {
  top: 48%;
  bottom: 51%;
}

#appsec >>> .links {
  margin-top: 75px;
  margin-left: 0;
}

.title {
  position: relative;
  margin-bottom: 100px;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 61px;
  left: 0;
  top: 142%;
  opacity: 1;
}

#items {
  padding: 100px 230px;
}

#items .title {
  padding-right: 20%;
}

#items >>> .col-lg-6 .row {
  margin-right: 32px;
}

#checks {
  background-image: url("../assets/Images/Coaches/Grafismo Jeunes y senior.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 0;
  padding: 16% 16% 20% 16%;
}

#checks .title {
  position: relative;
  margin-bottom: 80px;
}

#checks .title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 61px;
  left: 0;
  top: 130%;
  opacity: 1;
}

#checks .title h1 {
  color: #ffffff;
}

.check {
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: #0f1010;
  border: 1px solid #ffffff !important;
  display: inline-flex;
  align-items: center;
  padding: 12px 22px;
  margin: 0 30px 30px 0;
  border-radius: 16px;
  text-align: left;
  font: normal normal 500 22px/32px Montserrat;
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
  width: 100%;
  height: 85px;
}

.fa-chevron-right {
  margin-right: 10px;
  color: #ffffff;
}

.check:hover {
  background-color: rgba(255, 0, 0, 0.507);
  margin-left: 20px;
}

.check h5 {
  text-align: left;
  font: normal normal 500 22px/32px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.check img {
  width: 60px;
  height: 60px;
  margin-right: 40px;
}

#last {
  margin-bottom: 150px;
  padding: 0 300px;
}

@media (max-width: 1800px) {
  .padding {
    padding: 0 40px;
  }

  #sec1 >>> .text {
    padding: 80px 30px 0px 110px !important;
  }

  #sec2 >>> .text {
    padding: 0px 40px 0px;
  }

  #items {
    padding: 100px 50px;
  }

  #checks {
    padding: 22% 5%;
  }

  #secRot >>> .container-fluid {
    padding: 0 27px;
  }

  #secRot >>> #rot-0 .img-fluid {
    left: 39.5%;
  }

  #secRot >>> #rot-1 .img-fluid {
    left: 40.3%;
  }

  #secRot >>> #rot-2 .img-fluid {
    left: 39%;
  }

  #last {
    padding: 0 150px;
  }

  .card-coach h5 {
    margin-right: 5%;
  }

  .row-card {
    padding: 22% 10%;
  }

  #card-1 h5 {
    margin-right: 10% !important;
  }

  #card-1 .button-card button {
    padding: 28px 50px;
  }
}

@media (max-width: 1370px) {
  #sec1 >>> .text {
    padding: 50px 30px 0px 60px !important;
  }

  #sec1 >>> .img-fluid {
    height: auto;
  }

  #sec2 {
    padding: 4% 0 0 0;
  }

  #sec2 >>> .text {
    padding: 0 60px 0 0;
  }

  #sec2 >>> p {
    font-size: 24px;
    line-height: 40px;
  }

  #summary {
    padding-left: 8%;
    padding-right: 45%;
  }

  #secRot {
    padding: 10% 0;
  }

  #secRot >>> .container-fluid {
    padding: 0px 60px;
  }

  #sec3 {
    padding: 0;
  }

  #sec3 >>> .text {
    padding: 0 50px 0 0;
  }

  .row-card {
    padding: 26% 0%;
  }

  .card-coach h5 {
    font-size: 18px;
    line-height: 30px;
  }

  #items {
    padding: 100px;
  }

  #appsec >>> #second {
    padding: 0;
  }

  #checks {
    padding: 22% 4%;
  }

  #last {
    padding: 0 150px;
  }

  #summary h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 22px !important;
    text-align: center !important;
    line-height: 26px !important;
  }

  .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #mobile1 {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 217.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 110%;
    padding: 0 0 25% 0;
  }

  #mobile1 >>> h5 {
    text-align: center;
    font: normal normal 600 18px/21px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
  }

  #mobile2 {
    padding: 0;
  }

  #mobile2 >>> p {
    font-size: 16px;
    line-height: 18px;
    color: #0f1010;
    margin: 1rem 2.5%;
  }

  #mobile3 {
    margin-bottom: 75px;
  }

  #mobile3 >>> .title-sec {
    position: relative;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  #mobile3 >>> .title-sec::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 92px;
    left: 33%;
    bottom: -55%;
    opacity: 1;
  }

  #mobile3 >>> p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.26px;
    color: #242424;
  }

  #mobile3 >>> .btn-sc {
    padding: 34px 95px;
    margin-top: 62px;
    font-size: 16px;
    height: auto;
  }

  #items {
    padding: 15% 4%;
  }
  #items >>> .col-lg-6 .row {
    margin-right: -15px;
  }

  #items .title {
    padding-right: 0;
    margin-bottom: 34px;
  }

  .title::after {
    border-bottom: none;
  }

  #items >>> .item,
  #last >>> .item {
    padding: 22px 18px;
    margin-bottom: 14px;
  }

  #items >>> .item .img {
    margin-right: 15px;
  }

  #items >>> .item .img .fa-circle {
    width: 8px;
    height: 8px;
  }

  #items >>> .item .text p {
    font-size: 16px;
    line-height: 18px;
  }

  #secRot {
    background-image: none;
    padding: 10% 0;
  }

  #secRotM {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 218.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    padding: 20% 0;
  }

  #secRotM h1 {
    text-align: center;
    font: normal normal 800 23px/24px Montserrat !important;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 0 20px;
  }

  #secRot >>> .container-fluid {
    padding: 0;
  }

  #secRot >>> .rotate {
    border-radius: 50%;
    width: 93px;
    height: 93px;
    margin-left: 127px;
  }

  #secRot >>> .text {
    margin-top: 15px;
  }

  #secRot >>> .text h3 {
    font-size: 16px;
    line-height: 14px;
    text-transform: uppercase;
  }

  #secRot >>> .text p {
    font-size: 12px;
    line-height: 18px;
  }

  #secRot >>> #rot-0 .img-fluid {
    height: 56px;
    width: 56px;
    top: 7%;
    left: 42%;
  }

  #secRot >>> #rot-1 .img-fluid {
    height: 49px;
    width: 49px;
    top: 10%;
    left: 42.5%;
  }

  #secRot >>> #rot-2 .img-fluid {
    height: 50px;
    width: 50px;
    top: 15%;
    left: 96%;
  }

  #secRot >>> #rot-1,
  #secRot >>> #rot-2 {
    padding-top: 0;
  }

  #secRot >>> #rot-2 {
    margin-left: 58%;
  }

  #summary {
    padding: 7% 5%;
  }

  .row-card {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 219.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 20% 2%;
  }

  .row-card .card-coach {
    padding: 32px 0;
  }

  .row-card .card-coach h5 {
    font-size: 16px;
    line-height: 18px;
  }

  .row-card #card-0 {
    margin-bottom: 60px;
  }

  .row-card #card-0 img {
    margin: 0 14px;
    height: 76px;
  }

  .row-card #card-1 img {
    margin: 0 20px;
    height: 64px;
  }

  .row-card .button-card button {
    font: normal normal bold 14px/15px Montserrat;
    padding: 14px 65px;
  }

  .row-card #card-0 .button-card button {
    padding: 14px 65px;
  }

  .row-card #card-1 .button-card button {
    padding: 14px 40px;
  }

  #appsec {
    padding: 10% 0 27% 0;
    background-image: none;
  }

  #appsec >>> h2 {
    text-align: center;
    font: normal normal 800 23px/25px Montserrat;
    padding: 0 2%;
  }

  #appsec >>> .button-sec {
    margin-top: 46px;
    justify-content: center;
  }

  #appsec >>> .button-sec::after {
    left: 48%;
    top: 0;
    bottom: 48%;
  }

  #appsec >>> button {
    font-size: 18px;
    line-height: 15px;
    padding: 20px 60px;
    box-shadow: 0px 3px 20px #ff00004d;
  }

  #appsec >>> .links {
    margin-top: 95px;
    justify-content: center;
  }

  #appsec >>> a .img-fluid {
    margin-bottom: 20px;
    height: auto;
    margin-right: 0;
  }

  #checks {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 220.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 22% 8% 30% 8%;
    margin-bottom: 50px;
  }

  #checks .title::after {
    left: 40% !important;
    top: 120% !important;
  }

  #checks >>> .check .fa-chevron-right {
    margin-right: 10px;
  }

  #checks >>> .check {
    font: normal normal 600 15px/18px Montserrat;
    margin-bottom: 12px;
    margin-left: 10%;
  }

  #last {
    padding: 0 8%;
  }

  #checks .col-12,
  #last .col-12 {
    padding: 0;
  }

  #checks .title,
  #last .title {
    margin-bottom: 50px;
  }

  #last >>> .item {
    background-color: transparent;
    box-shadow: none;
    border: none;
    align-items: flex-start;
  }

  #last >>> .item .img {
    margin-right: 19px;
  }

  #last >>> .item .img img {
    height: 24px;
  }

  #last >>> .item .text p {
    font-size: 16px;
    color: #101010;
    line-height: 18px;
    font-weight: 600;
  }
}

@media (max-width: 425px) {
  #appsec >>> .button-sec::after {
    left: 44%;
    top: 0;
    bottom: 65%;
  }

  #mobile3 >>> .btn-sc {
    padding: 20px 95px;
    margin-top: 62px;
    font-size: 16px;
  }

  #secRot >>> .rotate {
    margin-left: 40px;
  }

  #secRot >>> #rot-0 .img-fluid {
    height: 56px;
    width: 56px;
    top: 7%;
    left: 32.5%;
  }

  #secRot >>> #rot-1 .img-fluid {
    height: 49px;
    width: 49px;
    top: 10%;
    left: 35%;
  }

  #secRot >>> #rot-2 .img-fluid {
    height: 50px;
    width: 50px;
    top: 15%;
    left: 84.5%;
  }

  .row-card {
    padding: 35% 2% 50% 2%;
  }
}

@media (max-width: 375px) {
  h1 {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  #mobile1 >>> h5 {
    font: normal normal 600 16px/19px Montserrat;
  }

  #mobile2 >>> p {
    line-height: 16px;
  }

  #mobile3 >>> p {
    line-height: 24px;
  }

  #mobile3 >>> .btn-sc {
    padding: 20px 85px;
  }

  #items >>> .item .text p {
    font-size: 14px;
    line-height: 18px;
  }

  #secRotM h1 {
    font: normal normal 800 21px/24px Montserrat !important;
  }

  #secRot >>> .text h3 {
    font-size: 16px;
    line-height: 14px;
  }

  #secRot >>> .text p {
    font-size: 12px;
    line-height: 18px;
  }

  .row-card .card-coach h5 {
    font-size: 14px;
    line-height: 18px;
  }

  .row-card .button-card button {
    font: normal normal bold 12px/15px Montserrat;
  }

  #appsec >>> h2 {
    font: normal normal 800 21px/25px Montserrat;
  }

  #appsec >>> button {
    font-size: 16px;
    line-height: 15px;
  }

  #checks >>> .check h5 {
    font: normal normal 600 11px/18px Montserrat;
  }

  #secRot >>> #rot-0 .img-fluid {
    height: 56px;
    width: 56px;
    top: 7%;
    left: 36.5%;
  }

  #secRot >>> #rot-1 .img-fluid {
    height: 49px;
    width: 49px;
    top: 10%;
    left: 39%;
  }

  #secRot >>> #rot-2 .img-fluid {
    height: 50px;
    width: 50px;
    top: 15%;
    left: 88.5%;
  }
}
</style>
